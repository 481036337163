@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --c-bg: #050505;
  --c-scrollbar: #111;
  --c-scrollbar-hover: #222;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: #000000;
  /* Somehow this doesn't always work (issue with messenger app browser etc. */
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

/*!
 * IMPORTANT:
 * If these values change,
 * change them in the tsparticles options as well
 * in the Particles.tsx component.
!*/
.avoid-cls {
  width: 100%;
  height: 5rem;
  max-width: 100%;
  max-height: 5rem;
}

nav li a.active span.nav-indicator {
  width: 4rem;
  background-color: rgb(226 232 240);
}

nav li a.active span {
  color: rgb(226 232 240);
}

table#content th {
  min-width: 10rem;
}

table#content thead tr th:first-child {
  min-width: 6rem;
}

@media screen and (max-height: 698px) {
  nav.nav > ul {
    margin-top: 0;
  }
  .small_laptop_hidden {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .small_laptop_hidden {
    display: block;
  }

  table#content th {
    min-width: unset;
  }
  
  table#content thead tr th:first-child {
    min-width: unset;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
  background: var(--c-bg);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--c-scrollbar);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--c-scrollbar-hover);
}